import { AccountAPI, ContactStore, SettingAPI, UnreadMsgAndNoticeStore, GUser, VideoMeetingStore, WorkStationStore } from "@gear/business";
import Loading from "@/assets/icon/loading.gif";
import ConfigStore from "@/stores/ConfigStore";
import PermissionStore from "@/stores/PermissionStore";
import TeamStore from "@/stores/TeamStore";
import enUs from "@/utils/locales/config/enUs.json";
import zhCn from "@/utils/locales/config/zhCn.json";
import es from "@/utils/locales/config/es.json";
import zhTw from "@/utils/locales/config/zhTw.json";

import enUsGu from "@gear/ui/commons/dicts/enUs.json";
import zhCnGu from "@gear/ui/commons/dicts/zhCn.json";
import esGu from "@gear/ui/commons/dicts/es.json";
import zhTwGu from "@gear/ui/commons/dicts/zhTw.json";

import enUsGb from "@gear/business/commons/dicts/enUs.json";
import zhCnGb from "@gear/business/commons/dicts/zhCn.json";
import esGb from "@gear/business/commons/dicts/es.json";
import zhTwGb from "@gear/business/commons/dicts/zhTw.json";

import enUS from "antd/es/locale/en_US";
import esES from "antd/es/locale/es_ES";
import zhCN from "antd/es/locale/zh_CN";
import zhTW from "antd/es/locale/zh_TW";

import "@/utils/request";
import {
  GTeamGanttStore,
  ssoLogout,
  wsInstance,
  observerStoreInstance,
  UnreadStore,
  useGReciveWsMessage,
  GWebSocketMessage,
  GWebSocketMessageName
} from "@gear/business";
import { initTranslation } from "@gear/ui";
import { ConfigProvider, message, Spin } from "antd";
import { configure } from "mobx";
import { observer, Provider } from "mobx-react";
import moment from "moment";
import "moment/locale/zh-cn";
import "moment/locale/zh-tw";
import "moment/locale/es";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import storage from "storejs";
import DailyReportsStore from "./stores/DailyReportsStore";
import UserStore from "./stores/UserStore";
import { getLanguage, getSSOHost, getWebsocketHost } from "./utils";
import TaskGanttStore from "./stores/TaskGanttStore";
import ConnectionStore from "./stores/ConnectionStore";
import { VirtualOfficeStore } from "./views/VirtualOffice/store/VirtualOfficeStore";
import AppDownloadStore from "./stores/AppDownloadStore";
import { AppStart } from "./components/AppStart";
import enterpriseStoreInstance from "@/stores/EnterpriseStore";
import { useAudioServerStateSync } from "./hooks/userAudioServerStateSync";
import "antd/dist/antd.less";
import "@gear/ui/dist/style.css";
import "@gear/business/dist/style.css";
import "./commons/common.scss";
import "./App.scss";
import { useTimezone } from "@/hooks/useTimezone";
import { useHistory } from "react-router";

// ******* mbox  *******
// 开启mobx的严格模式，规范数据修改操作只能在action中进行
configure({ enforceActions: "always" });

// ******* store  *******
window.rootStore = {
  userStore: new UserStore(),
  dailyReportsStore: new DailyReportsStore(),
  enterpriseStore: enterpriseStoreInstance,
  permissionStore: new PermissionStore(),
  teamStore: new TeamStore(),
  configStore: new ConfigStore(),
  teamGanttStore: new GTeamGanttStore(),
  taskGanttStore: new TaskGanttStore(),
  connectionStore: new ConnectionStore(),
  observerStore: observerStoreInstance,
  unreadStore: new UnreadStore(),
  virtualOfficeStore: new VirtualOfficeStore(),
  contactStore: new ContactStore(),
  workStationStore: new WorkStationStore(),
  unreadMsgAndNoticeStore: new UnreadMsgAndNoticeStore(),
  appDownloadStore: new AppDownloadStore(),
  videoMeetingStore: new VideoMeetingStore()
};

// *******  moment  *******
// moment.locale("zhCN");
moment.updateLocale("zh", {
  AM: "AM",
  am: "am",
  PM: "PM",
  pm: "pm"
});
// *******  antd  *******
message.config({
  top: 100, //消息距离顶部的位置
  duration: 5, //自动关闭的时长
  maxCount: 3 //最大显示数
  // icon:'', 自定义图标
});
// const antIcon = <img src={Loading} style={{ width: "40px", height: "40px" }} />;
// Spin.setDefaultIndicator(antIcon);

// *******  gear-ui translation  *******
initTranslation({
  enUs: Object.assign({}, enUsGu, enUsGb, enUs), // 英文词典
  zhCn: Object.assign({}, zhCnGu, zhCnGb, zhCn), // 中文简体词典
  es: Object.assign({}, esGu, esGb, es), // 西班牙语词典
  zhTw: Object.assign({}, zhTwGu, zhTwGb, zhTw), // 中文繁体词典
  lang: getLanguage("abridge") // 当前语言环境
});

const App = observer(() => {
  useAudioServerStateSync();
  const { i18n } = useTranslation();
  const { checkTimezone } = useTimezone();

  const uploadClarityEvent = () => {
    // 上报国家信息到 Clarity
    let shouldRequest = true;
    const city = JSON.parse(localStorage.getItem("city") || "{}");
    // @ts-ignore
    const clarity = window.clarity;
    if (city?.country?.name) {
      clarity("set", "country", city.country.name);
      clarity("set", "city", city.name);
      shouldRequest = +new Date() - (city.t || 0) > 600000;
    }
    if (shouldRequest) {
      SettingAPI.getCurrentCity()
        .then((res) => {
          const country = res.data?.country?.name;
          if (country) {
            clarity("set", "country", country);
            clarity("set", "city", res.data.name);
            res.data.t = +new Date();
            localStorage.setItem("city", JSON.stringify(res.data));
          }
        })
        .catch((e) => {});
    }
  };

  const initIntercom = () => {
    const intercomAppId = process.env.REACT_APP_INTERCOM_WORKSPACE_ID;
    if (!intercomAppId) return;

    const initializeIntercom = async () => {
      const intercomSettings: any = {
        api_base: "https://api-iam.intercom.io",
        app_id: process.env?.REACT_APP_INTERCOM_WORKSPACE_ID
      };
      const user = window.rootStore?.userStore?.user;
      if (user?.id) {
        intercomSettings.name = user.full_name;
        intercomSettings.user_id = user.profile?.id;
        intercomSettings.email = user.email;
        intercomSettings.created_at = user.date_joined;
        intercomSettings.user_hash = user.intercom_hash;
      }

      // @ts-ignore
      window.Intercom?.("boot", intercomSettings);
    };

    if (typeof window !== "undefined") {
      const interval = setInterval(() => {
        // @ts-ignore
        if (window.Intercom) {
          initializeIntercom();
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  };

  useEffect(() => {
    let isLogged = storage.get("token");
    if (storage.get("simulated_token")) {
      isLogged = storage.get("simulated_token");
    }

    if (isLogged) {
      if (!window.rootStore?.userStore.user) {
        //模拟登录中页面时不需要调用
        if (window.location.pathname.indexOf("/ticketlogin") < 0) {
          window.rootStore?.userStore.fetchUser((user: GUser) => {
            // 如果名下没有企业，退出登陆
            if (user.enterprises && user.enterprises.length === 0) {
              AccountAPI.logout()
                .then(() => {
                  ssoLogout(process.env.PUBLIC_URL || "", getSSOHost() || "", process.env.REACT_APP_APP_ID || "");
                })
                .catch((error) => {
                  message.error(error.response.data.detail);
                });
            } else {
              if (window.location.search.indexOf("select_timezone") < 0) {
                checkTimezone(user, false);
              }
            }
            // 如果使用上面的退出代码，很大概率会导致一直去自动登录然后回来又去自动登录，并没有真正把sso的token清理掉
            // if (value.agreement_required) {
            //   ssoLogout(process.env.PUBLIC_URL, getSSOHost() || "", process.env.REACT_APP_APP_ID || "");
            // }
          });
        }
      }

      window.setTimeout(() => {
        window.rootStore?.virtualOfficeStore?.collectDuration(true);
      }, 2000);

      uploadClarityEvent();
    }
  }, []);

  useEffect(() => {
    if (window.rootStore?.userStore.user?.id) {
      // 登录成功
      window.rootStore?.unreadStore?.fetchUnreads();
      window.rootStore.videoMeetingStore?.fetchMyZoomMeetingConfig();
      if (!wsInstance.wsState() || wsInstance.wsState() === WebSocket.CLOSED) {
        let workstationEnabled = window.rootStore?.enterpriseStore.enterprise?.metawork_opened ? 1 : 0;
        let token = storage.get("token");
        const simulatedToken = storage.get("simulated_token");
        if (simulatedToken) {
          token = simulatedToken;
          workstationEnabled = 0;
        }
        if (token) {
          const enterpriseId = storage.get("current-enterprise-id");
          wsInstance.setUrl(`${getWebsocketHost()}?token=${token}&enterprise=${enterpriseId}&workstation_enabled=${workstationEnabled}`);
          wsInstance.enableUserInteraction(!!workstationEnabled);
          wsInstance.create();
        }
      }
    } else {
      wsInstance.setUrl("");
      wsInstance.enableUserInteraction(false);
      wsInstance.close();
    }

    initIntercom();
  }, [window.rootStore?.userStore?.user?.id]);

  /**
   * 接受WebSocket回调
   */
  useGReciveWsMessage("App", (data: GWebSocketMessage<any>) => {
    if (data) {
      window.rootStore.workStationStore?.handleWsMessage(data);
      window.rootStore.contactStore?.handleWsMessage(data);
      window.rootStore.unreadMsgAndNoticeStore?.handleWsMessage(data);
      window.rootStore.videoMeetingStore?.handleWsMessage(data);

      switch (data.name) {
        case GWebSocketMessageName.InvalidToken:
          storage.remove("token");
          window.location.href = "/login";
          break;
      }
    }
  });

  useEffect(() => {
    if (window.rootStore?.userStore?.user?.id && window.rootStore?.enterpriseStore?.enterprise?.id) {
      // 更新第三方平台数据
      window.rootStore?.connectionStore?.fetchAll(true);
    }
  }, [window.rootStore?.enterpriseStore?.enterprise?.id, window.rootStore?.userStore?.user?.id]);

  const INTERNAL_ENTERPRISES = [200, 188, 158, 155, 131, 107, 106, 103, 83, 4, 20, 21, 30, 37];

  useEffect(() => {
    let userType = "unknown";
    const enterpriseId = window.rootStore?.enterpriseStore?.enterprise?.id;
    if (enterpriseId) {
      // 更新截图设置
      window.rootStore?.virtualOfficeStore?.updateEnterpriseId(enterpriseId);
      window.rootStore?.virtualOfficeStore?.fetchAllWorkStations();
      window.rootStore?.virtualOfficeStore?.fetchVOSettings();
      // 拉取工位、联系人数据
      window.rootStore?.workStationStore?.fetchAllWorkStations();
      window.rootStore?.contactStore?.fetchContacts();

      if (INTERNAL_ENTERPRISES.includes(enterpriseId)) {
        userType = "internal";
      } else {
        userType = "external";
      }
    }
    // @ts-ignore
    window.clarity("set", "user_type", userType);
  }, [window.rootStore?.enterpriseStore?.enterprise?.id]);

  useEffect(() => {
    if (i18n.language === "zh") {
      moment.updateLocale("zh-cn", {
        AM: "AM",
        am: "am",
        PM: "PM",
        pm: "pm"
      });
      return;
    }
    if (i18n.language === "zh-tw") {
      moment.updateLocale("zh-tw", {
        AM: "AM",
        am: "am",
        PM: "PM",
        pm: "pm"
      });
      return;
    }
    if (i18n.language === "en") {
      moment.updateLocale("en", {
        week: {
          dow: 7
        },
        weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
      });
      return;
    }
    if (i18n.language === "es") {
      moment.updateLocale("es", {
        monthsShort: "Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic".split("_")
      });
      return;
    }
    moment.updateLocale(i18n.language, {});
  }, [i18n.language]);

  const locale = useMemo(() => {
    switch (i18n.language) {
      case "en":
        return enUS;
      case "es":
        return esES;
      case "zh":
        return zhCN;
      case "zh-tw":
        return zhTW;
    }
  }, [i18n.language]);

  return (
    <ConfigProvider autoInsertSpaceInButton={false} locale={locale}>
      <Provider {...window.rootStore}>
        <AppStart rootStore={window.rootStore} />
      </Provider>
    </ConfigProvider>
  );
});

export default App;
